import classNames from 'classnames'
import { Icon, IconTypes } from '../Icon'
import { useLocalTranslation } from '../../hooks'

interface ButtonProps extends React.ComponentPropsWithRef<'button'> {
  translationText: string
  iconType?: IconTypes
  iconPlacement?: 'left' | 'right'
}

export function Button({ translationText, disabled, iconType, iconPlacement, ...props }: ButtonProps) {
  const t = useLocalTranslation()
  const baseStyles =
    'rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-border-600'
  const style = classNames(
    baseStyles,
    { 'bg-blue-600 hover:bg-blue-500': !disabled },
    { 'bg-gray-600 hover:gray-500': disabled },
  )
  return (
    <button {...props} className={style} disabled={disabled}>
      <span className='flex leading-relaxed items-center gap-2'>
        {iconType && iconPlacement == 'left' && <Icon type={iconType} color='white' />}
        {t(translationText)}
        {iconType && iconPlacement == 'right' && <Icon type={iconType} color='white' />}
      </span>
    </button>
  )
}
