import { useActionData, useLoaderData } from 'react-router-dom'
import { ApiResponse } from '../../../../api'
import { NavigateButton } from '../../../../components/buttons/navigate-button'
import { SubmitButton } from '../../../../components/buttons/submit-button'
import { Divider } from '../../../../components/divider'
import { Form } from '../../../../components/form'
import { FormElementProps } from '../../../../components/form'
import { InputField } from '../../../../components/form-fields/input'
import { LookupField, LookupFieldOption } from '../../../../components/form-fields/lookup'
import { FormNestedSection } from '../../../../components/form-nested-group'
import { FormWrapper } from '../../../../components/form-wrapper'
import { useDocumentTitle, useLocalTranslation } from '../../../../hooks'

export const processOrganisationPayload = (payload: OrganisationData) => ({
  ...payload,
  country: payload.country ? { description: payload.country } : null,
  defaultLanguage: payload.defaultLanguage ? { description: payload.defaultLanguage } : null,
  radiationUnitSystem: payload.radiationUnitSystem ? { description: payload.radiationUnitSystem } : null,
  measurementStandard: payload.measurementStandard ? { description: payload.measurementStandard } : null,
  learningManagementSystem: payload.learningManagementSystem ? { description: payload.learningManagementSystem } : null,
})

export type OrganisationData = {
  organisationName: string
  city: string
  mainContact: string
  primaryTechnicalContact: string
  country: LookupFieldOption
  defaultLanguage: LookupFieldOption
  radiationUnitSystem: LookupFieldOption
  measurementStandard: LookupFieldOption
  learningManagementSystem: LookupFieldOption
}

export const OrganisationDetailsForm = ({ action }: FormElementProps) => {
  const loaderData = useLoaderData() as ApiResponse<OrganisationData>
  const actionData = useActionData() as ApiResponse<OrganisationData>

  const t = useLocalTranslation()

  useDocumentTitle(!loaderData ? t('newOrganisation') : loaderData?.data?.organisationName ?? t('organisation'))

  return (
    <FormWrapper
      isError={actionData?.isError}
      error={actionData?.error}
      isSuccess={actionData?.isSuccess}
      successRedirectTo={action === 'create' ? `/admin/organisation` : undefined}
      successMessage={action === 'save' ? 'Organisation details saved' : undefined}
      locationState={{
        created: true,
        heading: `Organisation ${actionData?.data?.organisationName} added successfully`,
      }}
    >
      <Form method='post'>
        <FormNestedSection title='organisationDetails'>
          <InputField
            id='organisationName'
            title='organisationName'
            required
            type='text'
            defaultValue={loaderData?.data?.organisationName}
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.organisationName}
          />

          <InputField id='city' title='city' type='text' defaultValue={loaderData?.data?.city} />

          <LookupField
            id='country'
            lookupField='country'
            title='country'
            required
            defaultValue={loaderData?.data?.country}
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.country}
          />

          <InputField
            id='mainContact'
            title='mainContact'
            required
            type='text'
            helpText='ieDepartmentLead'
            defaultValue={loaderData?.data?.mainContact}
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.mainContact}
          />

          <InputField
            id='primaryTechnicalContact'
            title='primaryTechnicalContact'
            type='text'
            defaultValue={loaderData?.data?.primaryTechnicalContact}
          />
        </FormNestedSection>

        <Divider />

        <FormNestedSection title='integration'>
          <LookupField
            id='learningManagementSystem'
            lookupField='learning_management_system'
            title='learningManagementSystem'
            required
            defaultValue={loaderData?.data?.learningManagementSystem}
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.learningManagementSystem}
          />
        </FormNestedSection>

        <Divider />

        <FormNestedSection title='localisation'>
          <LookupField
            id='defaultLanguage'
            lookupField='language'
            title='defaultLanguage'
            required
            defaultValue={loaderData?.data?.defaultLanguage}
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.defaultLanguage}
          />

          <LookupField
            id='radiationUnitSystem'
            lookupField='radiation_units'
            title='radiationUnitSystem'
            required
            defaultValue={loaderData?.data?.radiationUnitSystem}
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.radiationUnitSystem}
          />

          <LookupField
            id='measurementStandard'
            lookupField='measurement_standard'
            title='measurementStandard'
            required
            defaultValue={loaderData?.data?.measurementStandard}
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.measurementStandard}
          />
        </FormNestedSection>
        <Divider />

        <div className='flex items-center justify-end gap-x-6'>
          <NavigateButton translationText='cancel' navigateTo='..' color='white' />
          <SubmitButton translationText={action} />
        </div>
      </Form>
    </FormWrapper>
  )
}
