import { AuthProvider } from './app/auth'
import { ErrorBoundary } from './app/components/error-boundary'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Router } from './app/router'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18nconfig'
import { NUM_REQUEST_RETRIES } from './app/constants'

// Multiple retries are needed to handle rare scenario which may result in error on first attempt
// to call Django API on very slow network connections
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: NUM_REQUEST_RETRIES, // Try multiple times in case auth token is currently being refreshed
      refetchOnWindowFocus: false, // Prevent the query from performing a refetch on window focus.
    },
    mutations: {
      retry: NUM_REQUEST_RETRIES, // Try multiple times in case auth token is currently being refreshed
    },
  },
})

// Wrap main router in providers in this file, so that we can mock them out as required for testing
export default function App() {
  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </I18nextProvider>
  )
}
