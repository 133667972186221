import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { getLocalDate } from '../../../utils'
import { ColumnHeader } from '../../../components/table/column-header'
import { SummaryTable } from '../../../components/table'
import { PageHeading } from '../../../components/page-heading'
import { SectionHeadingWithNav } from '../../../components/section-heading-with-nav'
import { recordedDateFormat } from '../constants'
import { useLocalTranslation } from '../../../hooks'

export interface ReportSummary {
  id: number
  simulation: string
  session: string
  recorded: Date
  uuid: string
  hasReportDetails: boolean
}

export function YourReport() {
  const t = useLocalTranslation()

  const columnHelper = createColumnHelper<ReportSummary>()
  const columns = [
    columnHelper.accessor('simulation', {
      header: () => <ColumnHeader header='report.simulation' />,
      // No need to call useLocalTranslation since this value comes already translated from the API
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('session', {
      header: () => <ColumnHeader header='report.session' />,
      // No need to call useLocalTranslation since this value comes already translated from the API
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('recorded', {
      header: () => <ColumnHeader header='report.recorded' />,
      cell: (info) => getLocalDate(info.getValue(), recordedDateFormat),
    }),
    columnHelper.accessor('uuid', {
      header: () => <span></span>,
      cell: (info) =>
        info.row.original.hasReportDetails ? (
          <Link
            to={`${info.getValue()}`}
            state={{ reportPath: '/report', name: 'report.yourReports' }}
            className='text-blue-500'
          >
            {t('report.viewReport')}
          </Link>
        ) : (
          '-'
        ),
      enableSorting: false,
    }),
  ]

  return (
    <>
      <PageHeading translationText='report.reports' />
      <SectionHeadingWithNav route='report' />
      <SummaryTable<ReportSummary>
        columns={columns}
        defaultColumnSort={{
          id: 'id',
          desc: true,
        }}
        endpoint='/api/v1/report'
        showGlobalFilter
        filterPlaceholderText='report.yourReportsSearch'
      />
    </>
  )
}
