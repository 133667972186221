import { RadiographyEvidenceItem } from './report-types'
import { ReportTable } from './report-table'
import { getConsoleConfig, getRadiationExposureConfig, getSettingsConfig, getTubeConfig } from './config'
import { PreviousNextNavigation } from './previous-next-navigation'
import { ReportPageCount } from './report-page-count'
import { UserNameAndEmail } from './user-name-and-email'
import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRequest } from '../../../hooks/use-request'
import { useParams } from 'react-router-dom'

type RadiographyReportProps = {
  evidence: Array<RadiographyEvidenceItem>
}

export function RadiographyReport({ evidence }: RadiographyReportProps) {
  const { reportId } = useParams()

  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)
  const [currentEvidenceIndex, setCurrentEvidenceIndex] = useState(0)
  const totalPages = evidence?.length ?? 0
  const reportData = useMemo(() => evidence?.[currentEvidenceIndex] ?? {}, [currentEvidenceIndex])
  const evidenceData = reportData.evidenceData
  const { client } = useRequest()

  useQuery({
    queryKey: [reportId, evidence?.[currentEvidenceIndex]?.id],
    queryFn: async () => {
      const response = client.get(`/api/v1/report/${reportId}/image/${evidence?.[currentEvidenceIndex]?.id}`)
      setImageUrl((await response).data['url'])
      return response
    },
  })

  function onClickPrevious() {
    if (currentEvidenceIndex > 0) {
      setCurrentEvidenceIndex(currentEvidenceIndex - 1)
    }
  }
  function onClickNext() {
    if (currentEvidenceIndex < evidence?.length - 1) {
      setCurrentEvidenceIndex(currentEvidenceIndex + 1)
    }
  }

  return (
    <div className='grid grid-cols-1 lg:grid-cols-7 gap-6 grid-flow-row-dense'>
      <img src={imageUrl} alt={`Image of ${reportData.practiceActivity}`} className='object-contain lg:col-span-3' />
      <div className='grid gap-4 col-span-3 lg:col-span-4'>
        <ReportPageCount
          localizationId={'report.imageNumber'}
          currentPageIndex={currentEvidenceIndex}
          totalPages={totalPages}
        />
        <div className='grid gap-6 grid-flow-col'>
          <div className='grid grid-cols-2 gap-4 lg:grid-cols-1 xl:grid-cols-2'>
            {totalPages > 1 && (
              <PreviousNextNavigation
                className='grid grid-flow-col gap-2 justify-center'
                isFirstPage={currentEvidenceIndex === 0}
                isLastPage={currentEvidenceIndex === evidence?.length - 1}
                onClickPrevious={onClickPrevious}
                onClickNext={onClickNext}
              />
            )}
            <UserNameAndEmail userEmail={reportData.userEmail} userName={reportData.userName} />
          </div>
        </div>
        {/* grid-cols-fill-230 is a custom style set in tailwind.config.js */}
        <div className='grid gap-6 lg:grid-cols-fill-230'>
          <ReportTable data={getSettingsConfig(evidenceData)} tableTitle='settings' />
          <ReportTable data={getConsoleConfig(evidenceData)} tableTitle='console' />
          <ReportTable data={getTubeConfig(evidenceData)} tableTitle='tube' />
          <ReportTable data={getRadiationExposureConfig(evidenceData)} tableTitle='radiationExposure' />
        </div>
      </div>
    </div>
  )
}
