import { Trans } from 'react-i18next'
import CompanyLogo from '../../../assets/logo.svg'
import { useUser } from '../../auth'
import { usePermission } from '../../auth/permissions'
import { UserPermissions } from '../../auth/types'
import { BRIGHTSPACE_HOSTNAME } from '../../constants'
import { useLocalTranslation } from '../../hooks'
import { Divider } from '../divider'
import { ListItemProps, NavItem } from './nav-item'
import { NavLink } from 'react-router-dom'
import { useMemo } from 'react'

interface NavOptions extends ListItemProps {
  isHidden?: boolean
}

export function SidebarMenu() {
  const [user] = useUser()
  const canAccessAdminSection = usePermission(UserPermissions.CLIENT_ACCESS_ADMIN_SECTION)
  const t = useLocalTranslation()

  const SELF_ASSESSMENT_LINK: NavOptions | null = BRIGHTSPACE_HOSTNAME
    ? {
        displayNameTranslation: 'selfAssessments',
        href: `https://${BRIGHTSPACE_HOSTNAME}/d2l/home`,
        icon: 'ArrowTopRightOnSquareIcon',
        target: '_blank',
      }
    : null
  const LEARNING_OPTIONS: NavOptions[] = [
    { displayNameTranslation: 'simulations', href: '/simulation', icon: 'Squares2X2Icon' },
    { displayNameTranslation: 'reports', href: '/report', icon: 'ChartBarIcon' },
    { displayNameTranslation: 'supportCentre.heading', href: '/support', icon: 'LifebuoyIcon' },
    { displayNameTranslation: 'download', href: `user/${user?.id}/downloads`, icon: 'ArrowDownTrayIcon' },
  ]
  const PROFILE_OPTIONS: NavOptions[] = useMemo(
    () => [
      { displayNameTranslation: 'preferences', href: `user/${user?.id}/preference`, icon: 'AdjustmentsVerticalIcon' },
      {
        displayNameTranslation: 'administration',
        href: '/admin/organisation',
        icon: 'Cog8ToothIcon',
        isHidden: !canAccessAdminSection,
      },
      {
        displayNameTranslation: 'signOut',
        href: '/logout',
        icon: 'ArrowRightOnRectangleIcon',
      },
    ],
    [user?.id],
  )

  // TODO: Review as a potential candidate for memoization
  const getNavOptions = (navOptions: NavOptions[]) =>
    navOptions
      .filter((options) => !options.isHidden)
      .map((Props: ListItemProps) => <NavItem key={Props.displayNameTranslation} {...Props} />)

  const learningOptions = getNavOptions(LEARNING_OPTIONS)
  const ProfileOptions = getNavOptions(PROFILE_OPTIONS)

  return (
    <div className='flex grow flex-col py-6 gap-y-7 overflow-y-auto border-r border-gray-200 bg-gray-50 px-6 max-w-xs h-screen'>
      <div className='flex h-24 shrink-0 justify-center '>
        <NavLink to={'/simulation'}>
          <img className='h-20 w-auto fill-current' src={CompanyLogo} alt='Your Company' />
        </NavLink>
      </div>
      <nav className='flex flex-1 flex-col'>
        <div role='list' className='flex flex-1 flex-col gap-y-2'>
          <div className='mx-2 space-y-1'>{learningOptions}</div>
          <Divider />
          <div className='mx-2 space-y-1'>{ProfileOptions}</div>
          <Divider />
          <div className='mx-2 space-y-1 py-4'>
            <h3 className='text-gray-600 pl-2'>{t('nav.linksHeader')}</h3>
            {SELF_ASSESSMENT_LINK ? (
              <NavItem {...SELF_ASSESSMENT_LINK} />
            ) : (
              <span className='group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 hover:text-gray-600 hover:bg-gray-50'>
                Brightspace is not linked to this environment
              </span>
            )}
          </div>
        </div>
      </nav>
      <div className='flex shrink-0 flex-col'>
        <span className='text-sm leading-6 font-normal text-gray-700 hover:text-gray-600 hover:bg-gray-50'>
          {`${t('nav.welcomeMessage', { firstName: user?.firstName, lastName: user?.lastName })} `}
          <NavLink to={'/logout'} className='text-blue-500'>
            {`${t('nav.logout')} `}
          </NavLink>
          {t('nav.toSignOut')}
        </span>
      </div>
    </div>
  )
}
