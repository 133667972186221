import classNames from 'classnames'
import {
  Squares2X2Icon,
  ChartBarIcon,
  LifebuoyIcon,
  AdjustmentsVerticalIcon,
  Cog8ToothIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  ArrowRightOnRectangleIcon,
  ArrowTopRightOnSquareIcon,
  PlusSmallIcon,
  ArrowPathIcon,
  TrashIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline'

type ICON_COLORS = {
  white: {
    base: 'bg-gray-50'
    bold: 'hover:bg-gray-50'
    hover: 'hover:bg-gray'
  }
  gray: {
    base: 'bg-gray-50'
    bold: 'bg-gray-100'
    hover: 'hover:bg-gray-50'
  }
  red: {
    base: 'bg-red-50'
    bold: 'bg-red-100'
    hover: 'hover:bg-red-50'
  }
}

interface StyleProps {
  color: keyof ICON_COLORS
  hasHoverState?: boolean
  isBold?: boolean
  className?: string
}

export type IconTypes =
  | 'Squares2X2Icon'
  | 'ChartBarIcon'
  | 'LifebuoyIcon'
  | 'AdjustmentsVerticalIcon'
  | 'Cog8ToothIcon'
  | 'ArrowRightOnRectangleIcon'
  | 'ArrowTopRightOnSquareIcon'
  | 'PlusIcon'
  | 'ExclamationTriangle'
  | 'ArrowRightIcon'
  | 'ArrowLeftIcon'
  | 'ArrowPathIcon'
  | 'TrashIcon'
  | 'ArrowDownTrayIcon'

interface IconProps extends StyleProps {
  type: IconTypes
}

interface ColorAttributes {
  base: string
  bold: string
  hover: string
}

interface GetIconColorProps extends StyleProps {
  colors?: Record<keyof ICON_COLORS, ColorAttributes>
}

function getIconColor({ color, isBold, hasHoverState, className, colors }: GetIconColorProps) {
  const baseColor = !isBold && colors?.[color].bold
  const baseBold = isBold && colors?.[color].bold
  const baseHover = hasHoverState && colors?.[color].hover

  return classNames('h-6 w-6 shrink-0', {
    baseColor,
    baseBold,
    baseHover,
    className,
  })
}

export function Icon({ type, color, hasHoverState, isBold, className }: IconProps) {
  const style = getIconColor({ color, isBold, hasHoverState, className })
  switch (type) {
    case 'Squares2X2Icon':
      return <Squares2X2Icon className={style} aria-hidden='true' />
    case 'ChartBarIcon':
      return <ChartBarIcon className={style} aria-hidden='true' />
    case 'LifebuoyIcon':
      return <LifebuoyIcon className={style} aria-hidden='true' />
    case 'AdjustmentsVerticalIcon':
      return <AdjustmentsVerticalIcon className={style} aria-hidden='true' />
    case 'Cog8ToothIcon':
      return <Cog8ToothIcon className={style} aria-hidden='true' />
    case 'ArrowRightIcon':
      return <ArrowRightIcon className={style} aria-hidden='true' />
    case 'ArrowLeftIcon':
      return <ArrowLeftIcon className={style} aria-hidden='true' />
    case 'ArrowRightOnRectangleIcon':
      return <ArrowRightOnRectangleIcon className={style} aria-hidden='true' />
    case 'ArrowTopRightOnSquareIcon':
      return <ArrowTopRightOnSquareIcon className={style} aria-hidden='true' />
    case 'PlusIcon':
      return <PlusSmallIcon className={style} aria-hidden='true' />
    case 'ArrowPathIcon':
      return <ArrowPathIcon className={style} aria-hidden='true' />
    case 'TrashIcon':
      return <TrashIcon className={style} aria-hidden='true' />
    case 'ArrowDownTrayIcon':
      return <ArrowDownTrayIcon className={style} aria-hidden='true' />
    default:
      return <></>
  }
}
