export const missingEvidenceId = -1

export interface ReportTableProps {
  tableTitle: string
  data: Config
}

export type IntlPlural = [string, { [key: string]: number | string }]

export interface RowAttrs {
  value: string | number | IntlPlural | undefined
  valueType: 'simpleIntl' | 'complexIntl' | 'rawString' | 'decimal' | 'number'
}

export interface Config {
  [key: string | number]: RowAttrs
}

interface Console {
  patientSize: 'Regular' | 'Slim' | 'Stout'
  detectorMode: 'FreeCassette' | 'TableBucky' | 'StandingBucky'
  AEC: boolean
  focalPoint: boolean
  leftAECChamber: boolean
  middleAECChamber: boolean
  rightAECChamber: boolean
  consolePreset: string
  mAsMode: boolean
}

interface Tube {
  collimatorTransverse: number
  collimatorLong: number
  tubeAngulation: number
  tubeRotation: number
  tubeDistance: number
  distanceUnits: string
}

export interface EvidenceMetaData {
  type: 'xray_image_details' | 'rs_lesson_part' // discriminator for new evidence types
  version: number
}

interface SimData {
  mode: string
  name: string
  version: string
  platform: 'PC' | 'Mac' | 'MetaQuest' | 'Unknown'
  renderingMode: 'Desktop' | 'Vr'
}

export interface EvidenceData {
  meta?: EvidenceMetaData // If not provided the evidence is assumed to be original release radiography evidence
  sim?: SimData // Only optional to support original release radiography evidence
}

export interface RadiographyEvidenceData extends EvidenceData {
  heading: string
  image: string
  submissionDetails: string
  patientPosition: string
  kVp: number
  mAs: number
  mA: number
  ms: number
  gridUsed: boolean
  suspendedRespiration: boolean
  timeToPerform: number
  doorClosed: boolean
  cassetteInBucky: 'N/A' | 'portrait' | 'landscape'
  DAP: number
  effectiveDose: number
  totalDAP: number
  totalEffectiveDose: number
  console: Console
  tube: Tube
  weightUsed: string
}

export interface RadiationSafetyLessonEvidenceData extends EvidenceData {
  lesson: LessonPartEvidenceData
}

export interface LessonPartEvidenceData {
  id: string
  count: number
  index: number
  partId: string
  doseTime: number
  partIndex: number
  doses: Doses
}

export interface Doses {
  eye: number
  chest: number
  hip: number
  [key: string]: number
}

export interface EvidenceItem {
  id: number
  image: string // not used but is returned by the server
  practiceActivity: string
  userName: string
  userEmail: string
  evidenceData: EvidenceData
}

export interface RadiographyEvidenceItem extends EvidenceItem {
  evidenceData: RadiographyEvidenceData
}

export interface RadiationSafetyLessonEvidenceItem extends EvidenceItem {
  evidenceData: RadiationSafetyLessonEvidenceData
}
