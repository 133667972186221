import { XCircleIcon } from '@heroicons/react/20/solid'
import { useLocalTranslation } from '../../hooks'

export const ErrorBanner = ({
  heading = 'submissionErrorsHeading',
  errors,
}: {
  heading?: string
  errors?: Record<string, Array<string>>
}) => {
  const t = useLocalTranslation()
  return (
    <div className='rounded-md bg-red-50 p-4'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
        </div>
        <div className='ml-3'>
          <h3 className='text-sm font-medium text-red-800'>{t(heading)}</h3>
          {errors && (
            <div className='mt-2 text-sm text-red-700'>
              <ul role='list' className='list-disc space-y-1 pl-5'>
                {Object.entries(errors).map(([field, messages], parentIndex) =>
                  Array.isArray(messages)
                    ? messages.map((message, childIndex) => (
                        <li key={`${parentIndex}-${childIndex}`}>
                          <span>{t(field)}: </span>
                          {t(message)}
                        </li>
                      ))
                    : t(messages),
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
