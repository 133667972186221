import { useLocalTranslation } from '../../../hooks'

export function InlineFieldError({ errorMessages }: { errorMessages: Array<string> }) {
  const t = useLocalTranslation()

  return (
    <ul role='list' className='list-disc space-y-1 pl-5'>
      {errorMessages?.map((errorMessage, index) => (
        <li key={index} className='text-sm leading-6 text-red-800'>
          {t(errorMessage)}
        </li>
      ))}
    </ul>
  )
}
