import { useState } from 'react'
import { useActionData, useLoaderData, useParams } from 'react-router-dom'
import { ApiResponse } from '../../../../../api'
import { NavigateButton } from '../../../../../components/buttons/navigate-button'
import { SubmitButton } from '../../../../../components/buttons/submit-button'
import { Divider } from '../../../../../components/divider'
import { Form } from '../../../../../components/form'
import { FormElementProps } from '../../../../../components/form'
import { DateField } from '../../../../../components/form-fields/date-field'
import { InputField } from '../../../../../components/form-fields/input'
import { LookupField, LookupFieldOption, LookupFieldOptions } from '../../../../../components/form-fields/lookup'
import { ReadOnlyField } from '../../../../../components/form-fields/read-only'
import { NumberField } from '../../../../../components/form-fields/number-field'
import { FormNestedSection } from '../../../../../components/form-nested-group'
import { FormWrapper } from '../../../../../components/form-wrapper'
import { dateToString, stringToDate } from '../../../../../utils'
import { ReadOnlyLookupField } from '../../../../../components/form-fields/read-only-lookup'
import { CheckboxField } from '../../../../../components/form-fields/checkbox'
import { Title } from '../../../../../components/form-fields/componentry/title'
import { useQuery } from '@tanstack/react-query'
import { useRequest } from '../../../../../hooks/use-request'

// When adding a field to this interface, please also add a keyName to the licenseDetailsKeys array below
export interface LicenseDetails {
  licenseName: string
  duration: LookupFieldOption
  startDate: string
  numberOfSeats: number
  salesReference: string
  organisation: number
  entitlements: number[]
}

// Keys are listed here as string[] in order to be able to convert submitted checkboxes in payload to number[] representing list of entitlements
// When adding a field to the LicenseDetails interface above, please also add a keyName to this array
const licenseDetailsKeys: string[] = [
  'licenseName',
  'duration',
  'startDate',
  'numberOfSeats',
  'salesReference',
  'organisation',
]

export const createEntitlementsArray = (payload: LicenseDetails) => {
  const entitlements: number[] = []
  // Code below iterates over all properties of payload objects, skips any generic props that every object has (prototype.hasOwnProperty),
  // and checks if prop name is one of those expected as per LicenseDetails interface. If not, it assumes this is one of the checkboxes
  // which control selection of entitlements
  for (const prop in payload) {
    if (Object.prototype.hasOwnProperty.call(payload, prop)) {
      if (!licenseDetailsKeys.includes(prop)) {
        entitlements.push(parseInt(prop))
      }
    }
  }
  return entitlements
}

export const processLicensePayload = (payload: LicenseDetails) => ({
  ...payload,
  entitlements: createEntitlementsArray(payload),
  duration: payload.duration ? { description: payload.duration } : null,
  startDate: payload.startDate ? payload.startDate : dateToString(new Date()),
})

export const setCheckboxState = (entitlements: number[], checkboxId: number) =>
  !!(entitlements && entitlements.includes(checkboxId))

export const LicenseBlockDetailsForm = ({ action }: FormElementProps) => {
  const { organisationId } = useParams()
  const loaderData = useLoaderData() as ApiResponse<LicenseDetails>
  const actionData = useActionData() as ApiResponse<LicenseDetails>
  const [duration, setDuration] = useState(loaderData?.data?.duration.description)
  const startDate = stringToDate(loaderData?.data?.startDate) ?? new Date()
  const [entitlementOptions, setEntitlementOptions] = useState<LookupFieldOptions | []>([])
  const { client } = useRequest()

  useQuery({
    queryKey: ['entitlement'],
    queryFn: async () => client.get<LookupFieldOptions>(`/api/v1/lookup?field=entitlement`),
    onSuccess: async ({ data }) => {
      setEntitlementOptions(data)
    },
  })

  return (
    <>
      <FormWrapper
        isError={actionData?.isError}
        error={actionData?.error}
        isSuccess={actionData?.isSuccess}
        successRedirectTo={action === 'create' ? `/admin/organisation/${organisationId}/license` : undefined}
        successMessage={action === 'save' ? 'License details saved' : undefined}
        locationState={{
          created: true,
          heading: `License ${actionData?.data?.licenseName} added successfully`,
        }}
      >
        <Form method='post'>
          <FormNestedSection title='licenseDetails'>
            <input type='hidden' name='organisation' value={organisationId} />
            <ReadOnlyLookupField title='organisation' lookupField='organisation' lookupId={organisationId ?? ''} />
            <InputField
              id='licenseName'
              defaultValue={loaderData?.data?.licenseName}
              title='licenseName'
              type='text'
              required
              hasError={!!actionData?.error}
              errorMessages={actionData?.error?.licenseName}
            />

            <LookupField
              id='duration'
              lookupField='license_duration'
              title='duration'
              required
              defaultValue={loaderData?.data?.duration}
              hasError={!!actionData?.error}
              errorMessages={actionData?.error?.duration}
              onChangeCallback={(e) => {
                const currentElement = e.target
                setDuration(currentElement.options[currentElement.selectedIndex].text)
              }}
            />

            {typeof duration === 'string' && duration?.toLowerCase() === 'inactive' && action === 'save' ? (
              <ReadOnlyField title='startDate' value={dateToString(startDate)} required />
            ) : (
              <DateField
                title='startDate'
                startDate={startDate}
                required
                hasError={!!actionData?.error}
                errorMessages={actionData?.error?.startDate}
              />
            )}

            <NumberField
              id='numberOfSeats'
              defaultValue={loaderData?.data?.numberOfSeats}
              title='numberOfSeats'
              minValue={0}
              required
              hasError={!!actionData?.error}
              errorMessages={actionData?.error?.numberOfSeats}
            />
            <InputField
              id='salesReference'
              defaultValue={loaderData?.data?.salesReference}
              title='salesReference'
              type='text'
              hasError={!!actionData?.error}
              errorMessages={actionData?.error?.salesReference}
            />
          </FormNestedSection>

          <Divider />
          <FormNestedSection>
            <Title id='entitlements-checkbox-section' title='entitlements' required={true} />
            {entitlementOptions.map((entitlement: LookupFieldOption) => (
              <CheckboxField
                id={`${entitlement.id}`}
                type='checkbox'
                title={entitlement.description.toString()}
                defaultChecked={setCheckboxState(loaderData?.data?.entitlements, parseInt(entitlement.id))}
              />
            ))}
          </FormNestedSection>

          <div className='flex items-center justify-end gap-x-6'>
            <NavigateButton translationText='cancel' navigateTo='..' color='white' />
            <SubmitButton translationText={action} />
          </div>
        </Form>
      </FormWrapper>
    </>
  )
}
