import classNames from 'classnames'
import { Link, NavLinkProps, To } from 'react-router-dom'
import { Icon, IconTypes } from '../Icon'
import { useLocalTranslation } from '../../hooks'

export interface NavigateButtonProps extends Pick<NavLinkProps, 'state'> {
  translationText: string
  navigateTo: To
  color: 'blue' | 'white' | 'light-blue'
  iconType?: IconTypes
  target?: '_blank'
  iconPlacement?: 'left' | 'right'
}

export function NavigateButton({
  iconType,
  translationText,
  navigateTo,
  color,
  target,
  state,
  iconPlacement = 'left',
}: NavigateButtonProps) {
  const t = useLocalTranslation()
  const baseStyles =
    'rounded-md px-3 py-2 text-sm grid align-center font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-border-600'
  const style = classNames(baseStyles, {
    'bg-gray-50 text-black border-gray-300 hover:bg-gray-50 border': color === 'white',
    'bg-blue-600 hover:bg-blue-500 text-white': color === 'blue',
    'bg-blue-100 text-blue-700 hover:bg-blue-200 focus-visible:outline-blue-600': color === 'light-blue',
  })
  return (
    <Link to={navigateTo} replace={true} className={style} state={state} target={target}>
      <span className='flex leading-relaxed items-center gap-2'>
        {iconType && iconPlacement == 'left' && <Icon type={iconType} color='white' />}
        {t(translationText)}
        {iconType && iconPlacement == 'right' && <Icon type={iconType} color='white' />}
      </span>
    </Link>
  )
}
